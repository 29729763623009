import React from 'react';

function ShopPage() {
  return (
    <>
      <h1>
        Lights on sometime in 2021
      </h1>
    </>
  );
}

export default ShopPage;